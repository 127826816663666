<template>
  <div>
    <div class="rtl">
      <v-card>
        <v-card-title>
          لیست کمپین ها
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="جست و جو"
              single-line
              hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="campaigns"
            :search="search"
        >
          <template v-slot:item.operations="{ item }">
            <v-btn class="whiteButton" style="margin-left: 5px;min-width: 120px!important;" @click="DeactivateCampaign(item.id)">غیر فعال سازی کمپین</v-btn>
          </template>
          <template v-slot:item.isActive="{ item }">
            <span>{{item.isActive ? 'فعال' : 'غیر فعال'}}</span>
          </template>
          <template v-slot:item.campaignFinishTimeAtUtc="{ item }">
            <span>{{getPersianDate(item.campaignFinishTimeAtUtc)}}</span>
          </template>
          <template v-slot:item.campaignStartTimeAtUtc="{ item }">
            <span>{{getPersianDate(item.campaignStartTimeAtUtc)}}</span>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col>
            <span>ایجاد کمپین جدید</span>
          </v-col>
        </v-row>
        <hr style="margin: 2rem 0">
        <v-row>
          <v-col cols="2">
            <span>نام کمپین</span>
          </v-col>
          <v-col cols="2">
            <input class="Input" v-model="title">
          </v-col>
          <v-col cols="2">
            <span>درصد</span>
          </v-col>
          <v-col cols="2">
            <input type="number" class="Input" v-model="referralSharePercentage">
          </v-col>
          <v-col cols="2">
            <span>کد کمپین</span>
          </v-col>
          <v-col cols="2">
            <input  class="Input" v-model="campaignCode">
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <span>تاریخ شروع</span>
          </v-col>
          <v-col cols="3">
            <satrex-date-picker v-model="campaignStartTimeAtUtc"></satrex-date-picker>
          </v-col>
          <v-col cols="3">
            <span>تاریخ پایان</span>
          </v-col>
          <v-col cols="3">
            <satrex-date-picker v-model="campaignFinishTimeAtUtc"></satrex-date-picker>
          </v-col>
        </v-row>
        <v-row style="display: flex ;justify-content: center">
          <v-btn class="whiteButton" @click="AddCampaign()">ایجاد</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axiosApi from "@/axios";
import Vue from "vue";
import convertDate from "@/plugins/convertDate";
import getPersianDateTime from "@/plugins/getPersianDate";
export default {
  name: "index.vue",
  data(){
    return {
      headers : [
        {text: 'وضعیت', value: 'isActive'},
        {text: 'کد', value: 'campaignCode'},
        {text: 'درصد سهم ارجاع', value: 'referralSharePercentage'},
        {text: 'تاریخ شروع ', value: 'campaignStartTimeAtUtc'},
        {text: 'تاریخ پایان', value: 'campaignFinishTimeAtUtc'},
        {text: 'عملیات', value: 'operations'},
      ],
      campaigns : [],
      title: '',
      campaignCode: '',
      referralSharePercentage: '',
      campaignStartTimeAtUtc: '',
      campaignFinishTimeAtUtc: '',
      search : ''
    }
  },
  mounted() {
    this.GetCampaigns()
  }
  ,
  methods : {
    GetCampaigns(){
      axiosApi().post('api/Manage/v1/ReferralShare/GetCampaigns')
          .then((result)=>{
            this.campaigns = result.data.data.campaigns
          })
    },
    AddCampaign() {
      axiosApi().post('api/Manage/v1/ReferralShare/AddCampaign', {
        title: this.title,
        campaignCode: this.campaignCode,
        referralSharePercentage: this.referralSharePercentage,
        campaignStartTimeAtUtc: convertDate(this.campaignStartTimeAtUtc),
        campaignFinishTimeAtUtc: convertDate(this.campaignFinishTimeAtUtc),
      }).then(({ data }) => {
        if (data['isSuccess'] === true) {
          Vue.$toast.open({
            message: 'کمپین با موفقیت اضافه شد',
            type: 'success',
            position: 'top-right'
          });
          this.GetCampaigns()
        } else {
          Vue.$toast.open({
            message: 'مقادیر ارسالی نا معتبر است',
            type: 'error',
            position: 'top-right'
          });
        }
      }).catch(() => {
        Vue.$toast.open({
          message: 'مقادیر ارسالی نا معتبر است',
          type: 'error',
          position: 'top-right'
        });
      });
    },
    getPersianDate(date) {
      return getPersianDateTime(date);
    },
    DeactivateCampaign(id){
      axiosApi().post('api/Manage/v1/ReferralShare/DeactivateCampaign',{
        campaignId : id
      }).then(()=>{
        Vue.$toast.open({
          message: 'کمپین با موفقیت غیر فعال شد',
          type: 'success',
          position:'top-right'
        })
        this.GetCampaigns()
      })
    }
  }
}
</script>

<style scoped>

</style>
